import styled from "styled-components";
import { Title, Paragraph } from "../home/home.styled";
import { images } from "../../constants/images.constant";

export const WrapSection1 = styled.div`
    /* padding-top: 80px; */
    /* padding-bottom: 60px; */
    background: #f3f3f3;
    margin-bottom: 50px;
    position: relative;

    .security {
        width: 120px;
        height: 120px;
        background: url(${images.ic_security});
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 50px;
        bottom: 0;
    }
`;

export const WrapSection2 = styled.div`

`;

export const WrapTitle = styled(Title)`
    font-size: 30px;
    margin-bottom: 20px;
`;

export const WrapSubTitle = styled.h2`
    font-size: 24px;
    margin-bottom: 20px;
    padding-left: 30px;
    /* font-family: "Poppins SemiBold"; */
`;

export const WrapParagraph = styled(Paragraph)`
    color: #898989;
    font-size: ${({ size }) => size || '18px'};
    margin-bottom: 40px;
    padding-left: 10px;
`;

export const WrapContent = styled.div`

`;


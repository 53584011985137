import React from "react";
import { Fade, Slide } from "react-reveal";
import { MainLayout } from "../main-layout/main-layout.component";
import { Container, Row, Col } from "react-bootstrap";
import { WrapSection1, WrapTitle, WrapSection2, WrapParagraph, WrapSubTitle, WrapContent } from "./refund.styled";

export const Refund = () => {
    return <MainLayout>
        <Fade top>
            <WrapSection1>
                <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "60px" }}>
                    <Row>
                        <Col className="text-center">
                            <WrapTitle>គោលការណ៍សងប្រាក់សម្រាប់ការផ្សព្វផ្សាយលើ ស្នេបឃ្យូ (Snapkyu)</WrapTitle>
                            <WrapParagraph size={"20px"}>យើងខិតខំផ្តល់បទពិសោធន៍ល្អបំផុតសម្រាប់ការផ្សព្វផ្សាយរបស់អ្នក។ សូមអានយ៉ាងម៉ត់ចត់នូវគោលការណ៍សងប្រាក់(Refund)មុនពេលធ្វើការទិញផ្សព្វផ្សាយណាមួយ។</WrapParagraph>
                            <div className="security" />
                        </Col>
                    </Row>
                </Container>
            </WrapSection1>
        </Fade>
        <Slide left>
            <WrapSection2>
                <Container style={{ width: '80%', margin: 'auto' }}>
                    <Row>
                        <Col>
                            <WrapContent>

                                {/* <WrapTitle>1 លក្ខណៈសម្បត្តិសម្រាប់ការស្នើសុំសងប្រាក់ (Refund)</WrapTitle> */}
                                <WrapSubTitle>1 លក្ខណៈសម្បត្តិសម្រាប់ការស្នើសុំសងប្រាក់ (Refund)</WrapSubTitle>
                                <WrapParagraph>
                                    យើងទទួលយកការស្នើសុំសងប្រាក់(Refund)សម្រាប់ការទិញផ្សព្វផ្សាយដែលមានតម្លៃចាប់ពីឬស្មើរ $50 ឡើងទៅប៉ុណ្ណោះ។ ការទិញដែលមានតម្លៃតិចជាងនេះមិនអាចទទួលបានការសងប្រាក់វិញទេ។
                                </WrapParagraph>

                                {/* <WrapTitle>2 ដំណើរការស្នើសុំសងប្រាក់ (Refund)</WrapTitle> */}
                                <WrapSubTitle>2 ដំណើរការស្នើសុំសងប្រាក់ (Refund)</WrapSubTitle>
                                <WrapParagraph>
                                    ដើម្បីស្នើសុំសងប្រាក់(Refund) សូមទាក់ទងក្រុមគាំទ្ររបស់យើងតាមអ៊ីមែល info@snapkyu.com ក្នុងរយៈពេល 30 ថ្ងៃបន្ទាប់ពីការទិញរបស់អ្នក។ សូមផ្តល់លេខការបញ្ជាទិញ យោបល់សម្រាប់ការស្នើសុំ និងឯកសារសម្របសម្រួលផ្សេងៗ។ ការស្នើសុំដែលមានចំនួនថ្ងៃលើសពី 30 ថ្ងៃនឹងមិនត្រូវបានពិចារណាទេ។
                                </WrapParagraph>

                                {/* <WrapTitle>3 ការពិនិត្យលើការស្នើសុំសងប្រាក់(Refund)</WrapTitle> */}
                                <WrapSubTitle>3 ការពិនិត្យលើការស្នើសុំសងប្រាក់(Refund)</WrapSubTitle>
                                <WrapParagraph>
                                    ការស្នើសុំសងប្រាក់(Refund)ទាំងអស់នឹងត្រូវបានពិនិត្យនិងពិចារណាដោយផ្អែកលើករណីនីមួយៗ។ ស្នេបឃ្យូ (Snapkyu) មានសិទ្ធិច្រានចោលការស្នើសុំសងប្រាក់(Refund)ប្រសិនបើការផ្សព្វផ្សាយត្រូវបានប្រើប្រាស់តាមបំណង ឬបញ្ហាដែលមិនទាក់ទងនឹងសេវាកម្មរបស់យើង។
                                </WrapParagraph>

                                {/* <WrapTitle>4 ពេលវេលាដំណើរការសងប្រាក់(Refund)</WrapTitle> */}
                                <WrapSubTitle>4 ពេលវេលាដំណើរការសងប្រាក់(Refund)</WrapSubTitle>
                                <WrapParagraph>
                                    ការសងប្រាក់ដែលត្រូវបានអនុម័ត នឹងត្រូវដំណើរការជាប្រចាំក្នុងរយៈពេល 10-15 ថ្ងៃធ្វើការ។ ប្រាក់នឹងត្រូវបានផ្ទេរវិញទៅវិញលើវិធីសាស្ត្របង់ប្រាក់ដើមដែលអ្នកបានប្រើនៅពេលទិញ។
                                </WrapParagraph>

                                {/* <WrapTitle>5 ករណីមិនអាចសងប្រាក់វិញបាន (Refund)</WrapTitle> */}
                                <WrapSubTitle>5 ករណីមិនអាចសងប្រាក់វិញបាន (Refund)</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>ការសងប្រាក់មិនអាចធ្វើបានសម្រាប់ការផ្សព្វផ្សាយដែលបានដំណើរការរួច។</li>
                                        <li>ការមិនពេញចិត្តដែលមិនទាក់ទងនឹងបញ្ហាជាមួយស្នេបឃ្យូ(Snapkyu) នឹងមិនមានសិទ្ធិទទួលបានការសងប្រាក់(Refund)។</li>
                                        <li>យើងមិនសងប្រាក់(Refund) សម្រាប់សេវាកម្មដែលបានប្រើប្រាស់អស់ខ្លះៗទេ។</li>
                                    </ul>
                                </WrapParagraph>

                                {/* <WrapTitle>6 ការផ្លាស់ប្តូរក្នុងគោលការណ៍សងប្រាក់(Refund)</WrapTitle> */}
                                <WrapSubTitle>6 ការផ្លាស់ប្តូរក្នុងគោលការណ៍សងប្រាក់(Refund)</WrapSubTitle>
                                <WrapParagraph>
                                    ស្នេបឃ្យូ (Snapkyu) មានសិទ្ធិផ្លាស់ប្តូរគោលការណ៍នេះនៅពេលណាដែលត្រូវការ។ ការផ្លាស់ប្តូរណាមួយនឹងត្រូវបានប្រកាសតាមវេទិការបស់យើង ហើយនឹងអនុវត្តចំពោះការទិញដែលមាននៅពេលអនាគតទាំងអស់។
                                </WrapParagraph>

                                <WrapSubTitle>

                                </WrapSubTitle>
                                <WrapParagraph>

                                </WrapParagraph>

                            </WrapContent>
                        </Col>
                    </Row>
                </Container>
            </WrapSection2>
        </Slide>

        <Fade top>
            <WrapSection1>
                <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "60px" }}>
                    <Row>
                        <Col className="text-center">
                            <WrapTitle>Snapkyu Advertisement Refund Policy</WrapTitle>
                            <WrapParagraph size={"20px"}>We strive to provide the best advertising experience for our users. Please read our refund policy carefully before making any purchase.</WrapParagraph>
                            <div className="security" />
                        </Col>
                    </Row>
                </Container>
            </WrapSection1>
        </Fade>
        <Slide left>
            <WrapSection2>
                <Container style={{ width: '80%', margin: 'auto' }}>
                    <Row>
                        <Col>
                            <WrapContent>

                                {/* <WrapTitle>1 លក្ខណៈសម្បត្តិសម្រាប់ការស្នើសុំសងប្រាក់ (Refund)</WrapTitle> */}
                                <WrapSubTitle>1 Refund Eligibility</WrapSubTitle>
                                <WrapParagraph>
                                Refunds are only available for advertising purchases equal or greater than $50. Purchases below this amount are non-refundable.
                                </WrapParagraph>

                                {/* <WrapTitle>2 ដំណើរការស្នើសុំសងប្រាក់ (Refund)</WrapTitle> */}
                                <WrapSubTitle>2 Refund Request Process</WrapSubTitle>
                                <WrapParagraph>
                                To request a refund, please contact our support team at info@snapkyu.com within 30 days of your purchase. Provide your order number, the reason for your refund request, and any supporting documentation. Refund requests submitted after the 30-day period will not be considered.
                                </WrapParagraph>

                                {/* <WrapTitle>3 ការពិនិត្យលើការស្នើសុំសងប្រាក់(Refund)</WrapTitle> */}
                                <WrapSubTitle>3 Evaluation of Refund Request</WrapSubTitle>
                                <WrapParagraph>
                                All refund requests will be reviewed on a case-by-case basis. Snapkyu reserves the right to deny a refund if the advertisement was used as intended, or if the issue is found to be unrelated to our service.
                                </WrapParagraph>

                                {/* <WrapTitle>4 ពេលវេលាដំណើរការសងប្រាក់(Refund)</WrapTitle> */}
                                <WrapSubTitle>4 Processing Time</WrapSubTitle>
                                <WrapParagraph>
                                Approved refunds will be processed within 10-15 business days. The refund will be issued to the original payment method used at the time of purchase.
                                </WrapParagraph>

                                {/* <WrapTitle>5 ករណីមិនអាចសងប្រាក់វិញបាន (Refund)</WrapTitle> */}
                                <WrapSubTitle>5 Non-Refundable Situations</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Refunds will not be issued for ads that have already run for their scheduled duration or delivered the agreed-upon impressions.</li>
                                        <li>Any claims of dissatisfaction unrelated to technical issues with the Snapkyu platform will not be eligible for a refund.</li>
                                        <li>No partial refunds will be given for services that were partially used.</li>
                                    </ul>
                                </WrapParagraph>

                                {/* <WrapTitle>6 ការផ្លាស់ប្តូរក្នុងគោលការណ៍សងប្រាក់(Refund)</WrapTitle> */}
                                <WrapSubTitle>6 Changes to the Refund Policy</WrapSubTitle>
                                <WrapParagraph>
                                Snapkyu reserves the right to modify this refund policy at any time. Any changes will be communicated via our platform and will apply to all future purchases.
                                </WrapParagraph>


                                <WrapSubTitle>

                                </WrapSubTitle>
                                <WrapParagraph>

                                </WrapParagraph>

                            </WrapContent>
                        </Col>
                    </Row>
                </Container>
            </WrapSection2>
        </Slide>

    </MainLayout>;
};

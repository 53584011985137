import React from "react";
import { Slide, Fade, Zoom } from "react-reveal";
import { MainLayout } from "../main-layout/main-layout.component";
import {
  Title,
  Paragraph,
  WrapSection1,
  WrapSection2,
  WrapSection3,
  WrapSection4,
  WrapSection5,
  WrapInfo,
  WrapImage,
} from "./home.styled";
import { Row, Col, Image, Container } from "react-bootstrap";
import { images } from "../../constants/images.constant";
import { StoreIcon } from "./store-icon/store-icon.component";
import { constants } from "../../constants/defaults.constant";

// import { SocialIcon } from "./social-icon/social-icon.component";

/*eslint-disable react/jsx-no-target-blank*/
export const Home = () => {
  // const [key, setKey] = useState(0);
  // const [scrollWindow, setScrollWindow] = useState(0);

  // useEffect(() => {
  //     const onScroll = e => {
  //         let scrollTop = e.target.documentElement.scrollTop;
  //         const clientHeight = e.target.documentElement.clientHeight;

  //         if ((scrollTop + clientHeight + 10) >= document.body.offsetHeight) {
  //             console.log("you're at the bottom of the page");
  //             setKey(scrollTop);
  //         }

  //         if (scrollTop - 1 < 0) {
  //             console.log("you're at the top of the page");
  //             setKey(scrollTop);
  //         }
  //         setScrollWindow(scrollTop);
  //     };
  //     window.addEventListener("scroll", onScroll);

  //     return () => window.removeEventListener("scroll", onScroll);
  // }, [scrollWindow]);

  return (
    <MainLayout>
        <WrapSection1>
        <Container style={{paddingTop: "30px" , paddingBottom: "40px"}}>
          <Row style={{ width: "80%", margin: "auto" }}>
            <Fade top>
                <Title fontSize={36}>#1 Social Media from Cambodians!</Title>
            </Fade>
          </Row>
        </Container>
      </WrapSection1>
      <WrapSection1>
        <Container>
          <Row style={{ width: "80%", margin: "auto" }}>
            <Col sm="12" md="6">
            <Slide left>
                <WrapImage>
                  <a href={"https://my.snapkyu.com"} target="_blank">
                    <Image
                      src={images.ic_MySnapku}
                    />
                  </a>
                </WrapImage>
              </Slide>
            </Col>
            <Col sm="12" md="6">
              <Slide right>
                <WrapImage>
                  <a href={"https://ads.snapkyu.com"} target="_blank">
                    <Image
                      src={images.ic_Ads}
                    />
                  </a>
                </WrapImage>
              </Slide>
            </Col>
          </Row>
          <Row style={{ paddingTop: "20px",width: "80%", margin: "auto" }}>
            <Paragraph>
                Snapkyu is a dynamic social networking platform designed to 
                bring friends and family closer through the sharing of photos 
                and videos. With an emphasis on user-friendly interactions and engaging content, 
                Snapkyu offers a vibrant space where users can connect, share, and explore visual stories.
            </Paragraph>
          </Row>
        </Container>
      </WrapSection1>

      {/* <WrapSection2>
            <Container>
                <Row style={{ width: '90%', margin: 'auto' }}>
                    <Col sm="12" md="6">
                        <Slide left>
                            <WrapImage>
                                <Image src={images.ic_phone2} />
                            </WrapImage>
                        </Slide>
                    </Col>
                    <Col sm="12" md="6">
                        <Fade top>
                            <WrapInfo>
                                <Title>Newsfeed</Title>
                                <Paragraph>
                                    Connect with friends, share what you're up
                                    to or see what's new from others all over the world. Expore
                                    our community where you can feel free to be yourself and share
                                    everything from your dialy moments to life's hightlights.
                                </Paragraph>
                            </WrapInfo>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </WrapSection2> */}

      {/* <WrapSection2>
            <Container>
                <Row style={{ width: '90%', margin: 'auto' }}>
                <Col sm="12" md="6">
                        <Fade top>
                            <WrapInfo>
                                <Title>Newsfeed</Title>
                                <Paragraph>
                                    Connect with friends, share what you're up
                                    to or see what's new from others all over the world. Expore
                                    our community where you can feel free to be yourself and share
                                    everything from your dialy moments to life's hightlights.
                                </Paragraph>
                            </WrapInfo>
                        </Fade>
                    </Col>
                    <Col sm="12" md="6">
                        <Fade top>
                            <WrapInfo>
                                <Title>Message</Title>
                                <Paragraph>Stay in touch with your friends and group privately with direct message.</Paragraph>
                            </WrapInfo>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </WrapSection2> */}

      {/* <WrapSection3>
            <Container>

                <Row style={{ width: '90%', margin: 'auto' }}>
                    <Col sm="12" md="6">
                        <Fade top>
                            <WrapInfo>
                                <Title>Message</Title>
                                <Paragraph>Stay in touch with your friends and group privately with direct message.</Paragraph>
                            </WrapInfo>
                        </Fade>
                    </Col>
                    <Col sm="12" md="6" className="text-right">
                        <Slide right>
                            <WrapImage>
                                <Image src={images.ic_phone3} />
                            </WrapImage>
                        </Slide>
                    </Col>
                </Row>
            </Container>
        </WrapSection3> */}

      {/* <WrapSection4>
            <Container>
                <Row style={{ width: '90%', margin: 'auto' }}>
                    <Col sm="12" md="6">
                        <Slide left>
                            <WrapImage>
                                <Image src={images.ic_phone4} />
                            </WrapImage>
                        </Slide>
                    </Col>
                    <Col sm="12" md="6">
                        <Fade top>
                            <WrapInfo>
                                <Title>Profile</Title>
                                <Paragraph>
                                    Post photos and video on newsfeed That you want to show to your friends and families
                                </Paragraph>
                            </WrapInfo>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </WrapSection4> */}

      <WrapSection5>
        <Container>
          <Row>
            <Col>
              <Zoom>
                <div className="text-center">
                  <Title fontSize={36} style={{ marginBottom: "30px" }}>
                    Get started on mobile
                  </Title>
                  <StoreIcon />
                  {/* <SocialIcon /> */}
                </div>
              </Zoom>
            </Col>
          </Row>
        </Container>
      </WrapSection5>
    </MainLayout>
  );
};

import React from "react";
import { Row, Navbar, Nav, Col, Image, Container } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { images } from "../../../constants/images.constant";
import { WrapLogo, WrapHeader } from "./header.styled";
import { PRIVACY_PAGE, HOME_PAGE, REFUND_PAGE, MARKET_PAGE } from "../../../constants/routes.constant";
export const Header = () => {
  return <Container>
    <WrapHeader>
      <Row>
        <Col>
          <Navbar expand="sm" style={{ position: 'relative' }}>
            <Navbar.Brand>
              <WrapLogo>
                <Link to="/">
                  <Image style={{width: "250px", height: "auto"}} src={images.ic_snapku} />
                  {/* <h5 style={{ fontWeight: 'bold', display: "inline-block", marginLeft: "15px" }}>SNAPKYU</h5> */}
                </Link>
              </WrapLogo>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
              <Nav>
                <NavLink to={`${HOME_PAGE}`} onClick={() => { window.scrollTo(0, 650); }} style={{ marginRight: '25px' }}>Features</NavLink>
                <NavLink to={PRIVACY_PAGE} style={{ marginRight: '25px' }}>Privacy</NavLink>
                <NavLink to={REFUND_PAGE} style={{ marginRight: '25px' }}>Refund Policy</NavLink>
                <NavLink to={MARKET_PAGE}>Marketplace Policy</NavLink>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row >
    </WrapHeader>
  </Container>
};

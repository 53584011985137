import React from "react";
import { Header } from "./header/header.component";
import { Footer } from "./footer/footer.component";

export const MainLayout = ({ children }) => {
    return (
        <div>
            <Header />
            {children}
            <Footer />
        </div>
    );
};

import styled, { css } from "styled-components";
import { images } from "../../constants/images.constant";

const groupStyle = css`
    padding-top: 100px;
    img {width:50%}
`;

export const Title = styled.h1`
    text-align: center;
    color: #DA474E;
    font-size: ${({ fontSize }) => fontSize ? fontSize + 'px' : '20px'};
`;

export const Paragraph = styled.p`
    color: #000000;
    font-size: ${({ fontSize }) => fontSize ? fontSize + 'px' : '20px'};
`;

export const WrapSection1 = styled.div`
    padding-top:20px;
`;

export const WrapSection2 = styled.div`
    /* padding-top: 100px;
    img {width:60%} 
    ${groupStyle}*/
`;

export const WrapSection3 = styled.div`
    margin-top: 100px;
    padding-top: 100px;
    img {
        width:50%
    }

    background-image: url(${images.ic_Bg});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
`;

export const WrapSection4 = styled.div`
    padding-top: 100px;
    img {width:50%}
`;

// REMOVE BACKGROUND IMAGE
export const WrapSection5 = styled.div`
    /* height: 1000px; */
    /* margin-top: 250px; */
    /* padding-top:100px; */
    padding-top: 50px;
    padding-bottom: 10px;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @media only screen and (max-width: 600px)  {
        height: 450px;
        margin-top: 150px;
        padding-top:100px;
        padding-bottom: 100px;
    }
`;

// export const WrapSection5 = styled.div`
//     /* height: 1000px; */
//     margin-top: 250px;
//     padding-top:300px;
//     padding-bottom: 200px;

//     background-image: url(${images.ic_allphone});
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: contain;

//     @media only screen and (max-width: 600px)  {
//         height: 450px;
//         margin-top: 150px;
//         padding-top:100px;
//         padding-bottom: 100px;
//     }
// `;

export const WrapInfo = styled.div`
    padding-top: 100px;
`;

export const WrapImage = styled.div`
    padding-top: 20px;
    padding-bottom:20px;
`;
import React from "react";
import { Image } from "react-bootstrap";
import { images } from "../../../constants/images.constant";
import { WrapIcon } from "./store-icon.style";
import { constants } from "../../../constants/defaults.constant";

/*eslint-disable react/jsx-no-target-blank*/
export const StoreIcon = ({ size }) => {

    return (
        <WrapIcon size={size}>
            <a href={constants.APP_STORE_URL} target="_blank">
                <Image src={images.ic_appstore} className="mr-2" />
            </a>
            <a href={constants.GOOGLE_PLAY_URL} target="_blank">
                <Image src={images.ic_googleplay} />
            </a>
        </WrapIcon>
    )
}
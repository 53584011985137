import React from "react";
import { Fade, Slide } from "react-reveal";
import { MainLayout } from "../main-layout/main-layout.component";
import { Container, Row, Col } from "react-bootstrap";
import { WrapSection1, WrapTitle, WrapSection2, WrapParagraph, WrapSubTitle, WrapContent } from "./market.styled";

export const Market = () => {
    return <MainLayout>
        <Fade top>
            <WrapSection1>
                <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "60px" }}>
                    <Row>
                        <Col className="text-center">
                            <WrapTitle>លក្ខខណ្ឌនៃទីផ្សារ Snapkyu <br/>ថ្ងៃមានប្រសិទ្ធិភាព៖ 30-តុលា-2024 តទៅ</WrapTitle>
                            <WrapParagraph size={"20px"}>លក្ខខណ្ឌទាំងនេះ ("លក្ខខណ្ឌ") គ្រប់គ្រងទំនាក់ទំនងរវាងអ្នកទិញ និងអ្នកលក់នៅលើទីផ្សារ Snapkyu ("ទីផ្សារ")។ ដោយប្រើប្រាស់<b>ទីផ្សារ</b> Snapkyu ជាអ្នកទិញ ឬអ្នកលក់ អ្នកយល់ព្រមជាមួយនឹងលក្ខខណ្ឌទាំងនេះ។ សូមអានយ៉ាងម៉ត់ចត់។</WrapParagraph>
                            <div className="security" />
                        </Col>
                    </Row>
                </Container>
            </WrapSection1>
        </Fade>
        <Slide left>
            <WrapSection2>
                <Container style={{ width: '80%', margin: 'auto' }}>
                    <Row>
                        <Col>
                            <WrapContent>

                                <WrapTitle>1 ការទទួលយកលក្ខខណ្ឌ</WrapTitle>
                                {/* <WrapSubTitle>1 ការទទួលយកលក្ខខណ្ឌ</WrapSubTitle> */}
                                <WrapParagraph>
                                    ដោយចូលប្រើប្រាស់ទីផ្សារ Snapkyu មិនថាជាអ្នកទិញ ឬអ្នកលក់ អ្នកយល់ព្រមនូវលក្ខខណ្ឌទាំងនេះ និងគោរពតាមគោលការណ៍ភាពឯកជនរបស់យើង។ ប្រសិនបើអ្នកមិនយល់ព្រម អ្នកមិនត្រូវប្រើទីផ្សារ Snapkyu នេះ។
                                </WrapParagraph>

                                <WrapTitle>2 ទិដ្ឋភាពទូទៅនៃទីផ្សារ</WrapTitle>
                                {/* <WrapSubTitle>2 ទិដ្ឋភាពទូទៅនៃទីផ្សារ</WrapSubTitle> */}
                                <WrapParagraph>
                                    <b>ទីផ្សារ</b> Snapkyu ផ្តល់ជាផ្លេតហ្វម (Platform) ដែលអនុញ្ញាតឲ្យអ្នកប្រើប្រាស់អាចបង្ហោះទំនិញ ទិញ និងលក់ផលិតផល ឬសេវាកម្ម ("ផលិតផល")។ <b>ទីផ្សារ</b> Snapkyu មានតួនាទីត្រឹមជាវេទិកាដែលភ្ជាប់អ្នកទិញ និងអ្នកលក់។ គ្រប់ប្រតិបត្តិការ និងការទូទាត់ប្រាក់នឹងកំណត់ដោយផ្ទាល់រវាងអ្នកទិញ និងអ្នកលក់។ <b>ទីផ្សារ</b> Snapkyu មិនចូលរួមក្នុងការទូទាត់ប្រាក់ ឬផ្តល់សេវាកម្មទូទាត់ទេ។
                                </WrapParagraph>

                                <WrapTitle>3 លក្ខណៈសម្បត្តិ</WrapTitle>
                                {/* <WrapSubTitle>3 លក្ខណៈសម្បត្តិ</WrapSubTitle> */}
                                <WrapParagraph>
                                    អ្នកត្រូវមានអាយុយ៉ាងតិច 18 ឆ្នាំដើម្បីប្រើប្រាស់<b>ទីផ្សារ</b> Snapkyu។ ដោយចុះឈ្មោះជាអ្នកទិញ ឬអ្នកលក់ អ្នកត្រូវធានា និងបញ្ជាក់ថាអ្នកមានលក្ខណៈសម្បត្តិនេះ។
                                </WrapParagraph>

                                <WrapTitle>4 លក្ខខណ្ឌសម្រាប់អ្នកទិញ</WrapTitle>
                                <WrapSubTitle>4.1 ការទិញផលិតផល</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកទិញមានសិទ្ធិចរចារទូទាត់ប្រាក់ដោយផ្ទាល់ជាមួយអ្នកលក់នៅខាងក្រៅពី<b>ទីផ្សារ</b> Snapkyu។ <b>ទីផ្សារ</b> Snapkyu មិនផ្តល់សេវាកម្មទូទាត់ទេ ហើយលក្ខខណ្ឌ និងវិធីទូទាត់ត្រូវបានចរចាដោយផ្ទាល់រវាងអ្នកទិញ និងអ្នកលក់។
                                    អ្នកទិញទទួលខុសត្រូវក្នុងការត្រួតពិនិត្យភាពត្រឹមត្រូវ និងភាពស្មោះត្រង់នៃអ្នកលក់មុនពេលបញ្ចប់ប្រតិបត្តិការ។ នៅពេលការទិញត្រូវបានបញ្ជាក់រវាងអ្នកទិញ និងអ្នកលក់ <b>ទីផ្សារ</b> Snapkyu មិនទទួលខុសត្រូវលើវិវាទណាមួយទាក់ទងនឹងការទូទាត់ប្រាក់ បញ្ហាដឹកជញ្ជូន ឬគុណភាពផលិតផល។
                                </WrapParagraph>

                                <WrapSubTitle>4.2 ការទទួលស្គាល់ហានិភ័យ</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកទិញយល់ព្រម និងទទួលស្គាល់ថា <b>ទីផ្សារ</b> Snapkyu មិនទទួលខុសត្រូវលើការទូទាត់ប្រាក់ ឬការបាត់បង់ក្នុងអំឡុងប្រតិបត្តិការ។ គ្រប់ប្រតិបត្តិការទាំងអស់ត្រូវបានធ្វើឡើងដោយហានិភ័យផ្ទាល់របស់អ្នកទិញ ហើយ<b>ទីផ្សារ</b>រ Snapkyu មិនត្រូវទទួលខុសត្រូវលើការខូចខាត ឬការសន្យាអាសន្នណាមួយឡើយ។
                                </WrapParagraph>

                                <WrapTitle>5 លក្ខខណ្ឌសម្រាប់អ្នកលក់</WrapTitle>
                                <WrapSubTitle>5.1 ការបង្ហោះផលិតផល និងសេវាកម្ម</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកលក់ត្រូវផ្តល់ព័ត៌មានដ៏ត្រឹមត្រូវ លម្អិត និងពេញលេញអំពីផលិតផល ឬសេវាកម្មដែលបានបង្ហោះ រួមមានតម្លៃ ស្ថានភាពនិងសភាពបច្ចុប្បន្ននៃផលិតផល ឬសេវាកម្ម។
                                    អ្នកលក់ត្រូវទទួលខុសត្រូវក្នុងការផ្គត់ផ្គង់ការបញ្ជាទិញក្នុងពេលវេលាត្រឹមត្រូវនិងយោងទៅតាមការពិពណ៌នានៃការបង្ហោះ។ <b>ទីផ្សារ</b> Snapkyu រក្សាសិទ្ធិលុបចោលការបង្ហោះណាមួយដែលផ្ទុយនឹងលក្ខខណ្ឌទាំងនេះ ឬច្បាប់ដែលអាចប្រើបាន។
                                </WrapParagraph>

                                <WrapSubTitle>5.2 ថ្លៃសេវាប្រចាំខែ</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកលក់ត្រូវបង់ថ្លៃសេវាប្រចាំខែដើម្បីបង្ហោះផលិតផល ឬសេវាកម្មនៅលើ<b>ទីផ្សារ</b> Snapkyu ។ ថ្លៃសេវានេះត្រូវបានគិតដើម្បីរក្សាអោយមានគណនីអ្នកលក់លើវេទិកា។ អ្នកលក់អាចបោះបង់ការជាវនៅពេលណាក៏បាន ហើយសិទ្ធិដើម្បីបង្ហោះផលិតផលនឹងត្រូវបានផ្អាកនៅពេលចប់រយៈពេលទូទាត់បច្ចុប្បន្ន។ <b>ទីផ្សារ</b> Snapkyu មិនផ្តល់ប្រាក់សងវិញសម្រាប់ថ្លៃសេវាដែលមិនបានប្រើរហូតដល់ការបោះបង់ការជាវ។
                                </WrapParagraph>

                                <WrapSubTitle>5.3 ការទូទាត់ប្រាក់ក្រៅពីទីផ្សារ Snapkyu</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកលក់ទទួលខុសត្រូវក្នុងការចរចារទូទាត់ប្រាក់ដោយផ្ទាល់ជាមួយអ្នកទិញ។ <b>ទីផ្សារ</b> Snapkyu មិនអាចធានា ឬរបៀបចរចាបានឡើយ។ គ្រប់ហានិភ័យ និងការបញ្ចប់ដំណើរការសេវា បញ្ហាអោយបង់ត្រលប់ប្រាក់គឺជាការទទួលខុសត្រូវផ្ទាល់របស់អ្នកលក់។
                                </WrapParagraph>

                                <WrapSubTitle>5.4 ផលិតផលដែលហាមឃាត់</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកលក់មិនត្រូវចុះបញ្ជីផលិតផលដែលខុសច្បាប់ អនាធិបតេយ្យ ឬក្លែងក្លាយ តាមគោលការណ៍នៃ<br/><b>ទីផ្សារ</b> Snapkyu ឬច្បាប់ដែលអាចប្រើបាន។
                                    <br/><b>ទីផ្សារ</b> Snapkyu រក្សាសិទ្ធិលុបចោលនូវការបង្ហោះផលិតផល ឬបញ្ឈប់ការប្រើប្រាស់ទីផ្សារ Snapkyu ។

                                </WrapParagraph>

                                <WrapSubTitle>5.5 ការទទួលស្គាល់ហានិភ័យ</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកលក់ទទួលស្គាល់និងយល់ព្រមថា <b>ទីផ្សារ</b> Snapkyu មិនទទួលខុសត្រូវលើការទូទាត់ប្រាក់ ឬបញ្ហាវិវាទជាមួយអ្នកទិញ។ <b>ទីផ្សារ</b> Snapkyu មិនចូលរួមក្នុងការជម្លោះ ឬការទូទាត់ប្រាក់នោះទេ។
                                </WrapParagraph>

                                <WrapSubTitle>5.6 ការទូទាត់ត្រឡប់ និងបង្វិលផលិតផល</WrapSubTitle>
                                <WrapParagraph>
                                    អ្នកលក់ត្រូវបញ្ជាក់យ៉ាងច្បាស់អំពីគោលនយោបាយស្តីពីការទូទាត់ត្រឡប់ និងបង្វិលផលិតផល។ អ្នកទិញមានសិទ្ធិបង្វិលត្រឡប់ផលិតផល យោងទៅតាមគោលនយោបាយដែលបានបញ្ជាក់ដោយអ្នកលក់។
                                    <br/><b>ទីផ្សារ</b> Snapkyu ផ្តល់អនុសាសន៍អោយអ្នកលក់ផ្តល់ប្រាក់សងវិញ ឬផ្តល់ជំនួសសម្រាប់ផលិតផលដែលខូច។
                                </WrapParagraph>

                                <WrapTitle>6 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    អ្នកប្រើប្រាស់<b>ទីផ្សារ</b> Snapkyu (ទាំងអ្នកទិញនិងអ្នកលក់) យល់ព្រមមិនធ្វើសកម្មភាពណាមួយដែលមានលក្ខណៈបោកប្រាស់ ឬមានបំណងល្មើសច្បាប់។
                                    សកម្មភាពណាមួយដែលមានលក្ខណៈបោកប្រាស់ មិនស្មោះត្រង់ ឬការរំខានអាចបណ្តាលឱ្យមានការផ្អាក ឬបិទគណនី និងទទួលខុសត្រូវចំពោះច្បាប់។
                                </WrapParagraph>

                                <WrapTitle>7 ការដោះស្រាយវិវាទ</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    <b>ទីផ្សារ</b> Snapkyu មិនចូលរួម ឬឈរនៅកណ្តាលវិវាទណាមួយរវាងអ្នកទិញ និងអ្នកលក់ឡើយ។ អ្នកទិញ និងអ្នកលក់ត្រូវដោះស្រាយវិវាទរបស់ពួកគេដោយផ្ទាល់ រួមទាំងបញ្ហាអំពីការទូទាត់ គុណភាពផលិតផល ឬការដឹកជញ្ជូន។
                                    <br/><b>ទីផ្សារ</b> Snapkyu មិនទទួលខុសត្រូវលើវិវាទណាមួយឡើយ ហើយអ្នកប្រើប្រាស់យល់ព្រម និងទទួលស្គាល់ថាពួកគេទទួលហានិភ័យនៅក្នុងគ្រប់ប្រតិបត្តិការ។
                                </WrapParagraph>

                                <WrapTitle>8 ការផ្អាក ឬបិទគណនី</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    <b>ទីផ្សារ</b> Snapkyu រក្សាសិទ្ធិផ្អាក ឬបិទគណនីអ្នកប្រើប្រាស់ណាមួយ (ទាំងអ្នកទិញនិងអ្នកលក់) នៅពេលណាក៏បាន ដែលបានបំពានលក្ខខណ្ឌទាំងនេះ ឬបានធ្វើសកម្មភាពបោកប្រាស់ ឬបង្ខូចប្រព័ន្ធ។
                                    នៅពេលផ្អាកគណនី អ្នកលក់នឹងត្រូវបាត់បង់ការចូលប្រើបញ្ជីផលិតផលរបស់ពួកគេ ហើយនឹងបាត់បង់នូវពេលដែលនៅសល់នៃការជាវដោយគ្មានការផ្តល់ប្រាក់សងវិញ។
                                </WrapParagraph>

                                <WrapTitle>9 ការកំណត់ច្បាប់នៃការទទួលខុសត្រូវ</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    <b>ទីផ្សារ</b> Snapkyu ផ្តល់វេទិកាមួយដើម្បីភ្ជាប់អ្នកទិញ និងអ្នកលក់ ប៉ុន្តែមិនទទួលខុសត្រូវលើបញ្ហាណាមួយដែលកើតឡើងពីប្រតិបត្តិការណាមួយរបស់អ្នកទិញ និងអ្នកលក់ រួមទាំងបញ្ហាផលិតផលខូចខាត ការទូទាត់ប្រាក់ ការដឹកជញ្ជូន ឬវិវាទផ្សេងៗ។
                                    <br/><b>ទីផ្សារ</b> Snapkyu មិនទទួលខុសត្រូវលើគ្រប់ការខូចខាតផ្ទាល់ បច្ចុប្បន្ន ឬដ៏អសកម្ម ឬនៃការបង់ប្រាក់ជាបន្ទុករបស់អ្នកប្រើប្រាស់នោះទេ។
                                </WrapParagraph>

                                <WrapTitle>10 សំណង</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    អ្នកយល់ព្រមទូទាត់សំណងដែលបង្កឡើងដោយទង្វើរបស់ខ្លួនឯង និងទទួលស្គាល់ថា Snapkyu និងផ្នែកសាខារបស់ Snapkyu នឹងមិនមានទំនួលខុសត្រូវចំពោះការទាមទារសំណង ការខូចខាត ឬការខាតបង់ណាមួយដែលកើតឡើងពីការប្រើប្រាស់ទីមុខងារ Marketplace របស់អ្នក ការរំលោភលើលក្ខខណ្ឌទាំងនេះ ឬការរំលោភសិទ្ធិភាគីទីបីនោះឡើយ ។
                                </WrapParagraph>

                                <WrapTitle>11 ច្បាប់ដែលគ្រប់គ្រង</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    លក្ខខណ្ឌទាំងនេះ និងវិវាទណាមួយដែលកើតឡើងពីការប្រើប្រាស់<b>ទីផ្សារ</b> Snapkyu នឹងត្រូវបានគ្រប់គ្រងដោយច្បាប់នៃប្រទេសកម្ពុជា។
                                </WrapParagraph>

                                <WrapTitle>12 ការផ្លាស់ប្ដូរលក្ខខណ្ឌ</WrapTitle>
                                {/* <WrapSubTitle>6.1 ការអភិវឌ្ឍន៍នៃការប្រើប្រាស់</WrapSubTitle> */}
                                <WrapParagraph>
                                    <b>ទីផ្សារ</b> Snapkyu រក្សាសិទ្ធិធ្វើការធ្វើបច្ចុប្បន្នភាពលក្ខខណ្ឌទាំងនេះនៅពេលណាក៏បាន។ អ្នកប្រើប្រាស់នឹងត្រូវបានជូនដំណឹងអំពីការផ្លាស់ប្ដូរ ហើយការបន្តប្រើប្រាស់ទីផ្សារគឺស្មើនឹងការយល់ព្រមលើលក្ខខណ្ឌដែលបានកែប្រែ។
                                </WrapParagraph>

                            </WrapContent>
                        </Col>
                    </Row>
                </Container>
            </WrapSection2>
        </Slide>

        <Fade top>
            <WrapSection1>
                <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "60px" }}>
                    <Row>
                        <Col className="text-center">
                            <WrapTitle>Snapkyu Marketplace Terms and Conditions <br/>Effective Date: 30-October-2024</WrapTitle>
                            <WrapParagraph size={"20px"}>These Terms and Conditions ("Terms") govern the relationship between buyers and sellers on the <b>Snapkyu Marketplace </b>(the "Marketplace"). By using the Marketplace as a buyer or seller, you agree to comply with these Terms. Please read them carefully.</WrapParagraph>
                            <div className="security" />
                        </Col>
                    </Row>
                </Container>
            </WrapSection1>
        </Fade>
        <Slide left>
            <WrapSection2>
                <Container style={{ width: '80%', margin: 'auto' }}>
                    <Row>
                        <Col>
                            <WrapContent>

                                <WrapTitle>1 Acceptance of Terms</WrapTitle>
                                {/* <WrapSubTitle>1 Acceptance of Terms</WrapSubTitle> */}
                                <WrapParagraph>
                                By accessing and using the Snapkyu Marketplace, whether as a buyer or seller, you agree to be bound by these Terms and our Privacy Policy. If you do not agree, you must not use the Marketplace.
                                </WrapParagraph>

                                <WrapTitle>2 Marketplace Overview</WrapTitle>
                                {/* <WrapSubTitle>2 Marketplace Overview</WrapSubTitle> */}
                                <WrapParagraph>
                                Snapkyu provides a platform where users can list, buy, and sell goods or services (the "Products"). Snapkyu acts solely as a platform for connecting buyers and sellers. All transactions, including payments, delivery, and product-related issues, are conducted between buyers and sellers directly. <b>Snapkyu does not process payments</b> or provide third-party payment services.
                                </WrapParagraph>

                                <WrapTitle>3 Eligibility</WrapTitle>
                                {/* <WrapSubTitle>3 Eligibility</WrapSubTitle> */}
                                <WrapParagraph>
                                You must be at least 18 years old to use the Snapkyu Marketplace. By registering as a buyer or seller, you represent and warrant that you meet this eligibility requirement.
                                </WrapParagraph>

                                <WrapTitle>4 Buyer Terms</WrapTitle>
                                <WrapSubTitle>4.1 Purchasing Products</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Buyers are free to arrange payment directly with sellers outside of the Snapkyu Marketplace. Snapkyu does not provide payment processing services, and all payment terms and methods are agreed upon directly between the buyer and seller.</li>
                                        <li>Buyers are responsible for verifying the accuracy and trustworthiness of sellers before completing transactions.</li>
                                        <li>Once a purchase is confirmed between a buyer and seller, Snapkyu is not responsible for any disputes, including non-payment, delivery issues, or product quality.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapSubTitle>4.2 Risk Acknowledgment</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li><b>Buyers acknowledge and agree</b> that Snapkyu is not responsible for any payments or losses incurred during transactions on the Marketplace. All transactions are conducted at the buyer’s own risk, and Snapkyu will not be held liable for any damages, misrepresentations, or claims.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>5 Seller Terms</WrapTitle>
                                <WrapSubTitle>5.1 Listing Products</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Sellers must provide accurate, detailed, and complete information about the Products they list, including pricing, availability, and condition.</li>
                                        <li>Sellers are responsible for fulfilling all orders in a timely manner and in accordance with the descriptions provided in the listing.</li>
                                        <li>Snapkyu reserves the right to remove any listings that violate these Terms or applicable laws.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapSubTitle>5.2 Monthly Subscription Fees</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Sellers are required to subscribe to a <b>monthly fee</b> in order to list their products on the Snapkyu Marketplace. This fee is charged to maintain an active seller account on the platform.</li>
                                        <li>Sellers can cancel their subscription <b>at any time</b>, and their access to listing products on the Marketplace will be revoked once the current billing period ends.</li>
                                        <li><b>No refunds</b> will be provided by Snapkyu for any unused portion of the subscription fee, even if the seller cancels before the end of the billing period.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapSubTitle>5.3 Payment Outside Snapkyu</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Sellers are responsible for arranging payment directly with buyers. Snapkyu does not facilitate, handle, or guarantee any payments made between buyers and sellers.</li>
                                        <li>All financial and logistical risks, including non-payment or chargebacks, are solely the responsibility of the seller.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapSubTitle>5.4 Prohibited Products</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Sellers are not allowed to list Products that are illegal, unsafe, counterfeit, or otherwise prohibited by Snapkyu’s policies or applicable laws.</li>
                                        <li>Snapkyu reserves the right to remove prohibited listings or suspend accounts found to be in violation.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapSubTitle>5.5 Risk Acknowledgment</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li><b>Sellers acknowledge and agree</b> that Snapkyu is not responsible for payments or disputes with buyers. Sellers bear the full risk of any transaction failures, non-payments, or disputes with buyers. Snapkyu will not mediate or take responsibility for any payment-related disputes.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapSubTitle>5.6 Returns and Refunds</WrapSubTitle>
                                <WrapParagraph>
                                    <ul>
                                        <li>Sellers must clearly define their return and refund policies. Buyers have the right to return Products in accordance with the seller’s stated policy.</li>
                                        <li>Snapkyu encourages sellers to offer refunds or exchanges for defective or misrepresented Products but does not enforce refund policies or guarantee any returns.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>6 User Conduct</WrapTitle>
                                {/* <WrapSubTitle>6.1 User Conduct</WrapSubTitle> */}
                                <WrapParagraph>
                                    <ul>
                                        <li>All users of the Snapkyu Marketplace (buyers and sellers) agree not to engage in any activities that are fraudulent, abusive, or in violation of applicable laws.</li>
                                        <li>Any abuse, such as misleading listings, payment fraud, or harassment, may result in account suspension or termination.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>7 Dispute Resolution</WrapTitle>
                                {/* <WrapSubTitle>7.1 Dispute Resolution</WrapSubTitle> */}
                                <WrapParagraph>
                                    <ul>
                                        <li><b>Snapkyu will not step in</b> or become involved in any disputes between buyers and sellers. Buyers and sellers must resolve disputes, including but not limited to issues regarding payment, product quality, or delivery, directly between themselves.</li>
                                        <li>Snapkyu <b>will not be held responsible</b> for any disputes, and users understand and accept that they bear the risks associated with all transactions.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>8 Termination of Account</WrapTitle>
                                {/* <WrapSubTitle>8.1 Termination of Account</WrapSubTitle> */}
                                <WrapParagraph>
                                    <ul>
                                        <li>Snapkyu reserves the right to terminate or suspend any user account, buyer or seller, at any time, for violating these Terms, fraudulent activity, or misuse of the platform.</li>
                                        <li>Upon termination, sellers will no longer have access to their listings and will forfeit any remaining time on their subscription without a refund.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>9 Limitation of Liability</WrapTitle>
                                {/* <WrapSubTitle>9.1 Limitation of Liability</WrapSubTitle> */}
                                <WrapParagraph>
                                    <ul>
                                        <li>Snapkyu provides a platform for buyers and sellers to connect but is not responsible for any issues that arise from transactions, including but not limited to defective products, non-payment, non-delivery, or disputes.</li>
                                        <li><b>Snapkyu will not be held liable</b> for any direct, indirect, incidental, or consequential damages arising from your use of the Marketplace. Buyers and sellers use the platform at their own risk.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>10 Indemnification</WrapTitle>
                                {/* <WrapSubTitle>10.1 Indemnification</WrapSubTitle> */}
                                <WrapParagraph>
                                    <ul>
                                        <li>You agree to indemnify and hold harmless Snapkyu and its affiliates from any claims, damages, or losses arising from your use of the Marketplace, violation of these Terms, or infringement of third-party rights.</li>
                                    </ul>
                                </WrapParagraph>

                                <WrapTitle>11 Governing Law</WrapTitle>
                                {/* <WrapSubTitle>11.1 Governing Law</WrapSubTitle> */}
                                <WrapParagraph>
                                These Terms and any disputes arising from your use of the Snapkyu Marketplace will be governed by the laws of Cambodian.
                                </WrapParagraph>

                                <WrapTitle>12 Changes to Terms</WrapTitle>
                                {/* <WrapSubTitle>12.1 Changes to Terms</WrapSubTitle> */}
                                <WrapParagraph>
                                Snapkyu reserves the right to update these Terms at any time. Users will be notified of changes, and continued use of the Marketplace signifies acceptance of the updated Terms.
                                </WrapParagraph>

                            </WrapContent>
                        </Col>
                    </Row>
                </Container>
            </WrapSection2>
        </Slide>

    </MainLayout>;
};

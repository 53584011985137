import React from "react";
import { Fade, Slide } from "react-reveal";
import { MainLayout } from "../main-layout/main-layout.component";
import { Container, Row, Col } from "react-bootstrap";
import { WrapSection1, WrapTitle, WrapSection2, WrapParagraph, WrapSubTitle, WrapContent } from "./privacy.styled";

export const Privacy = () => {
  return <MainLayout>
    <Fade top>
      <WrapSection1>
        <Container style={{ position: "relative", paddingTop: "80px", paddingBottom: "60px" }}>
          <Row>
            <Col className="text-center">
              <WrapTitle>We are here to help you</WrapTitle>
              <WrapParagraph size={"20px"}>You should read this policy in full</WrapParagraph>
              <div className="security" />
            </Col>
          </Row>
        </Container>
      </WrapSection1>
    </Fade>
    <Slide left>
      <WrapSection2>
        <Container style={{ width: '80%', margin: 'auto' }}>
          <Row>
            <Col>
              <WrapContent>

                <WrapTitle>1 Information You Share With Us</WrapTitle>
                <WrapSubTitle>1.1 Basic Account Information</WrapSubTitle>
                <WrapParagraph>
                  After you signing in using your phone number, Gmail or Facebook account you must provide us with some personal data so that we can provide our services to you. On Snapkyu this includes a display name (for example, “Snapkyu123”) and an email address or phone number. Your display name always public, but you can use either your real name or a nick name. You can also create and manage multiple Snapkyu accounts, for example to express different parts of your identity.
                </WrapParagraph>
                
                <WrapSubTitle>1.2 Public Information</WrapSubTitle>
                <WrapParagraph>
                  Most activity on Snapkyu is public, including your profile information, your time zone and language, when you signing using phone number, Gmail or Facebook account, and your Posts and certain information about your Posts like the date, time, and application and version of Snapkyu you Posted from. You also may choose to publish your location in your Posts or your Snapkyu profile. The lists you create, people you follow, accepted friends and who follow you, and Posts you Like or Direct Messages are also public.
                </WrapParagraph>

                <WrapParagraph>
                  If you Like, Direct Messages, reply, or otherwise publicly engage with an ad on our services, that advertiser might thereby learn information about you associated with the ad with which you engaged such as characteristics of the audience the ad was intended to reach.
                </WrapParagraph>

                <WrapParagraph>
                  Broadcasts you create, click on, or otherwise engage with on Snapkyu, are public along with when you took those actions. So are your hearts, comments, the number of hearts you’ve received, which accounts you are a Superfan of, and whether you watched a broadcast live or on replay. Any hearts, comments, or other content you contribute to another account’s broadcast will remain part of that broadcast for as long as it remains on Snapkyu.

  Information posted about you by other people who use our services may also be public. For example, other people may tag you in a photo (you can remove tag on the post you’re tagged in) or mention you in a Post. You are responsible for your Posts and other information you provide through our services, and you should think carefully about what you make public, especially if it is sensitive information. If you update your public information on Snapkyu, such as by deleting a Post or deactivating your account, we will reflect your updated content on Snapkyu.com, Snapkyu for iOS, and Snapkyu for Android.
                </WrapParagraph>

                <WrapParagraph>
                  By publicly posting content when you Post, you are directing us to disclose that information as broadly as possible, including through our APIs, and directing those accessing the information through our APIs to do the same. To facilitate the fast global dissemination of Posts to people around the world, we use technology like application programming interfaces (APIs) and embeds to make that information available websites, apps, and others for their use - for example, displaying Posts on a news website or analyzing what people say on Snapkyu. We generally make this content available in limited quantities for free and charge licensing fees for large-scale access. We have standard terms that govern how this data can be used, and a compliance program to enforce these terms. But these individuals and companies are not affiliated with Snapkyu, and their offerings may not reflect updates you make on Snapkyu.
                </WrapParagraph>

                <WrapSubTitle>1.3 Contact Information and Address Books</WrapSubTitle>
                <WrapParagraph>
                  We use your contact information, such as your email address or phone number, to authenticate your account and keep it - and our services - secure, and to help prevent spam, fraud, and abuse. We also use contact information to enable certain account features (for example, for login verification or Snapkyu via SMS), and to send you information about our services, and to personalize our services, including ads.

  If you provide us with your phone number, you agree to receive text messages from Snapkyu to that number as your country’s laws allow. Snapkyu also uses your contact information to market to you as your country’s laws allow, and to help others find your account if your settings permit, including through third-party services and client applications. You can use your settings for email and mobile notifications to control notifications you receive from Snapkyu. You can also unsubscribe from a notification by following the instructions contained within the notification option.
                </WrapParagraph>
                <WrapParagraph>
                  You can choose to upload and sync your address book on Snapkyu so that we can help you find and connect with people you know and help others find and connect with you. We also use this information to better recommend content to you and others.
                </WrapParagraph>


                <WrapSubTitle>1.4 Direct Messages and Non-Public Communications</WrapSubTitle>
                <WrapParagraph>
                  We provide certain features that let you communicate more privately or control who sees your content. For example, you can use Direct Messages to have non-public conversations on Snapkyu, protect your Posts. When you communicate with others by sending or receiving Direct Messages, we will store and process your communications and information related to them. This includes link scanning for malicious content, link shortening to http://x. co URLs, detection of spam, abuse and prohibited images, and use of reported issues. We also use information about whom you have communicated with and when (but not the content of those communications) to better understand the use of our services, to protect the safety and integrity of our platform, and to show more relevant content. We share the content of your Direct Messages with the people you’ve sent them to; we do not use them to serve you ads. Note that if you interact in a way that would ordinarily be public with Snapkyu content shared with you via Direct Message, for instance by liking a Post, those interactions will be public. When you use features like Direct Messages to communicate, remember that recipients have their own copy of your communications on Snapkyu - even if you delete your copy of those messages from your account - which they may duplicate, store, or share.
                </WrapParagraph>

                <WrapSubTitle>
                  1.5 Payment Information
                </WrapSubTitle>
                <WrapParagraph>
                  You may provide us with payment information, including your credit or debit card number, card expiration date, CVV code, and billing address, in order to purchase advertising or other offerings provided as part of our services.
                </WrapParagraph>

                <WrapSubTitle>
                  1.6 How You Control the Information You Share with Us
                </WrapSubTitle>
                <WrapParagraph>
                  Your Privacy and safety settings let you decide:
                <ul>
                    <li>Whether your Posts are publicly available on Snapkyu </li>
                    <li>Whether others can tag you in a photo </li>
                    <li>Whether you will be able to receive Direct Messages from anyone on Snapkyu or just your followers</li>
                    <li>Whether others can find you based on your email or phone number </li>
                    <li>Whether you upload your address book to Snapkyu for storage and use </li>
                    <li>When and where you may see sensitive content on Snapkyu </li>
                    <li>Whether you want to block or mute other Snapkyu accounts </li>
                  </ul>
                </WrapParagraph>

                <WrapTitle>
                  2 Additional Information We Receive About You
                </WrapTitle>
                <WrapSubTitle>
                  2.1 Location Information
                </WrapSubTitle>
                <WrapParagraph>
                  We require information about your signup and current location, which we get from signals such as your IP address or device settings, to securely and reliably set up and maintain your account and to provide our services to you. Subject to your settings, we may collect, use, and store additional information about your location - such as your current precise position or places where you’ve previously used Snapkyu – to operate or personalize our services including with more relevant content like local trends, stories, ads, and suggestions for people to follow.
                </WrapParagraph>

                <WrapSubTitle>
                  2.2 Links
                </WrapSubTitle>
                <WrapParagraph>
                  In order to operate our services, we keep track of how you interact with links across our services. This includes links in emails we send you and links in Posts that appear on other websites or mobile applications. If you click on an external link or ad on our services, that advertiser or website operator might figure out that you came from Snapkyu along with other information associated with the ad you clicked such as characteristics of the audience it was intended to reach. They may also collect other personal data from you.
                </WrapParagraph>

                <WrapSubTitle>2.3 Log Data</WrapSubTitle>
                <WrapParagraph>
                  We receive information when you view content on or otherwise interact with our services, which we refer to as “Log Data,” even if you have not created an account. For example, when you visit our websites, sign into our services, interact with our email notifications, use your account to authenticate to a third-party service, or visit a third-party service that includes Snapkyu content. We also receive Log Data when you click on, view, or interact with links on our services, including when you install another application through Snapkyu. We use Log Data to operate our services and ensure their secure, reliable, and robust performance. For example, we use Log Data to protect the security of accounts and to determine what content is popular on our services. We also use this data to improve the content we show you, including ads and to improve the effectiveness of our own marketing.
                </WrapParagraph>
                <WrapParagraph>
                  We use information you provide to us and data we receive, including Log Data and data from third parties, to make inferences like what topics you may be interested in, how old you are, and what languages you speak. This helps us better promote and design our services for you and personalize the content we show you, including ads.
                </WrapParagraph>

                <WrapSubTitle>
                  2.4 Advertisers and Other Ad Partners
                </WrapSubTitle>
                <WrapParagraph>
                  Advertising revenue allows us to support and improve our services. We use the information described in this Privacy Policy to help make our advertising more relevant to you, to measure its effectiveness, and to help recognize your devices to serve you ads on and off of Snapkyu. Our ad partners and affiliates share information with us such as browser cookie IDs, mobile device IDs, hashed email addresses, demographic or interest data, and content viewed or actions taken on a website or app. Some of our ad partners, particularly our advertisers, also enable us to collect similar information directly from their website or app by integrating our advertising technology. Information shared by ad partners and affiliates or collected by Snapkyu from the websites and apps of ad partners and affiliates may be combined with the other information you share with Snapkyu and that Snapkyu receives about you described elsewhere in our Privacy Policy.
                </WrapParagraph>

                <WrapParagraph>
                  Snapkyu adheres to the Digital Advertising Alliance Self-Regulatory Principles for
                  Online Behavioral Advertising (also referred to as “interest-based advertising”) and respects the DAA’s consumer choice tool for you to opt out of interest-based advertising at https://optout.aboutads.info. In addition, our ads policies prohibit advertisers from targeting ads based on categories that we consider sensitive or are prohibited by law, such as race, religion, politics, sex life, or health.
                </WrapParagraph>

                <WrapParagraph>
                  If you are an advertiser or a prospective advertiser, we process your personal data to help offer and provide our advertising services. You can update your data in your Snapkyu Ads dashboard or by contacting us directly as described in this Privacy Policy.
                </WrapParagraph>

                <WrapSubTitle>
                  2.5 Developers
                </WrapSubTitle>
                <WrapParagraph>
                  If you access our APIs or developer portal, we process your personal data to help provide our services. You can update your data by contacting us directly as described in this Privacy Policy.
                </WrapParagraph>

                <WrapSubTitle>
                  2.6 Other Third Parties and Affiliates
                </WrapSubTitle>
                <WrapParagraph>
                  We may receive information about you from third parties who are not our ad partners, such as others on Snapkyu, partners who help us evaluate the safety and quality of content on our platform, and other services you link to your Snapkyu account. You may choose to connect your Snapkyu account to accounts on another service, and that other service may send us information about your account on that service. We use the information we receive to provide you features like cross posting or cross-service authentication, and to operate our services. For integrations that Snapkyu formally supports, you may revoke this permission at any time from your application or phone settings; for other integrations, please visit the other service you have connected to Snapkyu.
                </WrapParagraph>

                <WrapSubTitle>
                  2.7 Personalizing Based On Your Inferred Identity
                </WrapSubTitle>
                <WrapParagraph>
                  When you log into Snapkyu on a browser or device, we will associate that browser or device with your account for purposes such as authentication, security, and personalization. Subject to your settings, we may also associate your account with browsers or devices other than those you use to log into Snapkyu (or associate your logged-out device or browser with other browsers or devices). When you provide other information to Snapkyu, including an email address, we associate that information with your Snapkyu account. Subject to your settings, we may also use this information in order to infer other information about your identity, for example by associating your account with hashes of email addresses that share common components with the email address you have provided to Snapkyu. We do this to operate and personalize our services. For example, if you visit websites with sports content on your laptop, we may show you sports-related ads on Snapkyu for Android and, if the email address associated with your account shares components with another email address, such as shared first name, last name, or initials, we may later match advertisements to you from advertisers that were trying to reach email addresses containing those components.
                </WrapParagraph>

                <WrapSubTitle>
                  2.8 How You Control Additional Information We Receive
                </WrapSubTitle>
                <WrapParagraph>
                  Your Snapkyu Personalization and data settings let you decide:
                  <ul>
                    <li>Whether we show you interest-based ads on and off Snapkyu</li>

                    <li>How we personalize based on your inferred identity</li>

                    <li>Whether we collect and use your precise location</li>

                    <li>Whether we personalize your experience based on where you’ve been</li>

                    <li>Whether we keep track of the websites where you see Snapkyu content</li>
                  </ul>

                  You can use Your Snapkyu data to review:
                  <ul>
                    <li>Advertisers who have included you in tailored audiences to serve you</li>

                    <li>Demographic and interest data about your account from our ads</li>

                    <li>Information that Snapkyu has inferred about you such as your age range, gender, languages, and interests</li>
                  </ul>
                </WrapParagraph>

                <WrapParagraph>We also provide a version of these tools on Snapkyu if you don’t have an Snapkyu account, or if you’re logged out of your account. This lets you see the data and settings for the logged out browser or device you are using, separate from any Snapkyu account that uses that browser or device.</WrapParagraph>

                <WrapTitle>
                  3 Information We Share and Disclose
                </WrapTitle>
                <WrapSubTitle>
                  3.1 Sharing You Control
                </WrapSubTitle>
                <WrapParagraph>
                  We share or disclose your personal data with your consent or at your direction, such as when you authorize a third-party web client or application to access your account or when you direct us to share your feedback with a business. If you’ve shared information like Direct Messages or protected Posts with someone else who accesses Snapkyu through a third-party service, keep in mind that the information may be shared with the third-party service. Subject to your settings, we also provide certain third parties with personal data to help us offer or operate our services. For example, we share with advertisers the identifiers of devices that saw their ads, to enable them to measure the effectiveness of our advertising business. You can control whether Snapkyu shares your personal data in this way by using the “Share your data with Snapkyu’s business partners” option in your Personalization and Data settings. (This setting does not control sharing described elsewhere in our Privacy Policy, such as when we share data with our service providers.) The information we share with these partners does not include your name, email address, phone number, or Snapkyu username, but some of these partnerships allow the information we share to be linked to other personal information if the partner gets your consent first.
                </WrapParagraph>

                <WrapSubTitle>
                  3.2 Service Providers
                </WrapSubTitle>
                <WrapParagraph>
                  We engage service providers to perform functions and provide services to us in the United States, Ireland, and other countries. For example, we use a variety of third-party services to help operate our services, such as hosting our various blogs and wikis, and to help us understand the use of our services, such as Google Analytics. We may share your private personal data with such service providers subject to obligations consistent with this Privacy Policy and any other appropriate confidentiality and security measures, and on the condition that the third parties use your private personal data only on our behalf and pursuant to our instructions (service providers may use other non-personal data for their own benefit). We share your payment information with payment services providers to process payments; prevent, detect, and investigate fraud or other prohibited activities; facilitate dispute resolution such as chargebacks or refunds; and for other purposes associated with the acceptance of credit and debit cards.
                </WrapParagraph>

                <WrapSubTitle>
                  3.3 Law, Harm, and the Public Interest
                </WrapSubTitle>
                <WrapParagraph>
                  Notwithstanding anything to the contrary in this Privacy Policy or controls we may otherwise offer to you, we may preserve, use, or disclose your personal data or other safety data if we believe that it is reasonably necessary to comply with a law, regulation, legal process, or governmental request; to protect the safety of any person; to protect the safety or integrity of our platform, including to help prevent spam, abuse, or malicious actors on our services, or to explain why we have removed content or accounts from our services8; to address fraud, security, or technical issues; or to protect our rights or property or the rights or property of those who use our services. However, nothing in this Privacy Policy is intended to limit any legal defenses or objections that you may have to a third party’s, including a government’s, request to disclose your personal data.
                </WrapParagraph>

                <WrapSubTitle>
                  3.4 Affiliates and Change of Ownership
                </WrapSubTitle>
                <WrapParagraph>
                  In the event that we are involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your personal data may be sold or transferred as part of that transaction. This Privacy Policy will apply to your personal data as transferred to the new entity. We may also disclose personal data about you to our affiliates in order to help operate our services and our affiliates’ services, including the delivery of ads.
                </WrapParagraph>

                <WrapSubTitle>
                  3.5 Non-Personal Information
                </WrapSubTitle>
                <WrapParagraph>
                  We share or disclose non-personal data, such as aggregated information like the total number of times people engaged with a Post, demographics, the number of people who clicked on a particular link or voted on a poll in a Post (even if only one did), the topics that people are Posting about in a particular location, some inferred interests, or reports to advertisers about how many people saw or clicked on their ads.
                </WrapParagraph>

                <WrapTitle>
                  Managing Your Personal Information With Us
                </WrapTitle>
                <WrapSubTitle>
                  4.1 Accessing or Rectifying Your Personal Data
                </WrapSubTitle>
                <WrapParagraph>
                  If you have registered an account on Snapkyu, we provide you with tools and account settings to access, correct, delete, or modify the personal data you provided to us and associated with your account. You can download certain account information, including your Posts. To submit a request related to access, modification or deletion of your information, you may also contact us as specified in the How To Contact Us section of our Privacy Policy (Additional Information or Assistance).
                </WrapParagraph>

                <WrapSubTitle>
                  4.2 Deletion
                </WrapSubTitle>
                <WrapParagraph>
                  We keep Log Data for a maximum of 18 months. When deactivated, your Snapkyu account, including your display name, username, and public profile, will no longer be viewable on Snapkyu.com, Snapkyu for iOS, and Snapkyu for Android. For up to 30 days after deactivation it is still possible to restore your Snapkyu account if it was accidentally or wrongfully deactivated. Keep in mind that search engines and other third parties may still retain copies of your public information, like your profile information and public Posts, even after you have deleted the information from our services or deactivated your account.
                </WrapParagraph>

                <WrapSubTitle>
                  4.3 Object, Restrict, or Withdraw Consent
                </WrapSubTitle>
                <WrapParagraph>
                  When you are logged into your Snapkyu account, you can manage your privacy settings and other account features at any time. It may take a short amount of time for privacy settings to be fully reflected throughout our systems.
                </WrapParagraph>

                <WrapSubTitle>
                  4.4 Portability
                </WrapSubTitle>
                <WrapParagraph>
                  Snapkyu provides you a means to download the information you have shared through our services by following the steps.
                </WrapParagraph>

                <WrapSubTitle>
                  4.5 Additional Information or Assistance
                </WrapSubTitle>
                <WrapParagraph>
                  Thoughts or questions about this Privacy Policy? Please let us know by contacting us or writing to us at the appropriate address below.
                </WrapParagraph>

                <WrapParagraph>
                  Attn: Privacy Policy Inquiry<br />

                  S14, Standford<br />

                  Phnom Penh, Cambodia<br />

                  Email: info@snapkyu.com<br />
                </WrapParagraph>
                
                <WrapTitle>
                  5 Children and Our Services
                </WrapTitle>
                <WrapParagraph>
                  Our services are not directed to children, and you may not use our services if you are under the age of 13. You must also be old enough to consent to the processing of your personal data in your country (in some countries we may allow your parent or guardian to do so on your behalf).
                </WrapParagraph>

                <WrapTitle>
                  6 Our Global Operations and Privacy Shield
                </WrapTitle>
                <WrapParagraph>
                  To bring you our services, we operate globally. Where the laws of your country allow you to do so, you authorize us to transfer, store, and use your data in the United States, Ireland, and any other country where we operate. In some of the countries to which we transfer personal data, the privacy and data protection laws and rules regarding when government authorities may access data may vary from those of your country.
                </WrapParagraph>
                <WrapParagraph>
                  When we transfer personal data outside of the European Union or EFTA States, we ensure an adequate level of protection for the rights of data subjects based on the adequacy of the receiving country’s data protection laws, contractual obligations placed on the recipient of the data (model clauses may be requested by inquiry as described below), or EU-US and Swiss-US Privacy Shield principles.
                </WrapParagraph>
                <WrapParagraph>
                  If you have a Privacy Shield-related complaint, please contact us. As part of our participation in Privacy Shield, if you have a dispute with us about our adherence to the Principles, we will seek to resolve it through our internal complaint resolution process, alternatively through the independent dispute resolution body and under certain conditions, through the Privacy Shield arbitration process.
                </WrapParagraph>

                <WrapTitle>
                  7 Changes to This Privacy Policy
                </WrapTitle>
                <WrapParagraph>
                  We may revise this Privacy Policy from time to time. The most current version of the policy will govern our processing of your personal data and will always be at https:// Snapkyu.com/privacy. If we make a change to this policy that, in our sole discretion, is material, we will notify you via an @Snapkyu update or email to the email address associated with your account. By continuing to access or use the Services after those changes become effective, you agree to be bound by the revised Privacy Policy.
  Effective: January 1, 2020
                </WrapParagraph>



                <WrapSubTitle>

                </WrapSubTitle>
                <WrapParagraph>

                </WrapParagraph>

              </WrapContent>
            </Col>
          </Row>
        </Container>
      </WrapSection2>
    </Slide>
  </MainLayout>;
};

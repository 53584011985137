import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { WrapFooter } from "./footer.styled";


const date = new Date();
const year = date.getFullYear();

export const Footer = () => {

  return (
  <Container>
    <Row>
      <Col>
        <WrapFooter>Copyright ©2021 SNAPKYU™. All Rights Reserved</WrapFooter>
      </Col>
    </Row>
  </Container>)
};


import ic_phone1 from "../assets/images/ic_phone1@2x.png";
import ic_phone2 from "../assets/images/ic_phone2@2x.png";
import ic_phone3 from "../assets/images/ic_phone3@2x.png";
import ic_phone4 from "../assets/images/ic_phone4@2x.png";
import ic_allphone from "../assets/images/ic_allphone@2x.png";
import ic_appstore from "../assets/images/ic_appstore@2x.png";
import ic_facebook from "../assets/images/ic_facebook@2x.png";
import ic_googleplay from "../assets/images/ic_googleplay@2x.png";
import ic_instragram from "../assets/images/ic_instragram@2x.png";
import ic_linkedin from "../assets/images/ic_linkedin@2x.png";
import ic_security from "../assets/images/ic_security@2x.png";
import ic_twitter from "../assets/images/ic_twitter@2x.png";
import ic_Bg from "../assets/images/ic_Bg@2x.png";
import ic_snapku from "../assets/images/ic_snapkyu@2x.png";
import ic_Ads from "../assets/images/Ads.png";
import ic_MySnapku from "../assets/images/MySnapkyu.png";

export const images = {
    ic_phone1,
    ic_phone2,
    ic_phone3,
    ic_phone4,
    ic_allphone,
    ic_appstore,
    ic_facebook,
    ic_googleplay,
    ic_instragram,
    ic_linkedin,
    ic_security,
    ic_twitter,
    ic_Bg,
    ic_snapku,
    ic_Ads,
    ic_MySnapku
}
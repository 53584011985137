import styled from "styled-components";

export const WrapHeader = styled.div`

    .navbar-collapse.collapse {
        position: absolute;
        right: 0;
    }

    @media only screen and (max-width: 560px)  {
        .navbar-collapse.collapse {
            position: relative;
            right: 0;
        }

        .navbar-nav {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    padding-top: 15px;
    padding-bottom: 15px;
    a {
        color: #000;
        /* font-weight: bold; */

        &:link {
            text-decoration:none;
        }

        &:hover{
            color: #DA474E;
        }
    }

    .navbar-nav {
        a.active {           
            color: #DA474E;
            font-weight:bold;
        }
    }
`;

export const WrapLogo = styled.div`
    img{
        width: 40px;
    }
`;
import styled from "styled-components";

export const WrapIcon = styled.div`
    display: flex;
    justify-content: center;
    gap: 8px;
    img {
        width:${({ size }) => size || '200px'};
    }

    img:nth-child(1) {
        cursor: pointer;
    }

    @media only screen and (max-width: 600px)  {
        img {
            width:120px;
        }
    }
`;